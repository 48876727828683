.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.plenaryHoverHotspot {
  position: absolute;
  top: 42.9%;
  left: 60.6%;
  width: 23.5%;
  height: 12%;
}

.postersHoverHotspot {
  top: 85%;
  left: 75%;
  width: 22.2%;
  height: 15%;
}

.exhibitionHoverHotspot {
  top: 83.7%;
  left: 10.3%;
  width: 25%;
  height: 12.5%;
}

.breakoutsHoverHotspot {
  top: 18.7%;
  left: 65.3%;
  width: 12%;
  height: 14.5%;
}

.networkingHoverHotspot {
  top: 12.7%;
  left: 10.3%;
  width: 10%;
  height: 20.5%;
}


.onDemandHoverHotspot {
  top: 48%;
  left: 13%;
  width: 10%;
  height: 8%;
}

.exhibitionHoverHotspot {
  top: 83%;
  left: 10%;
  width: 28%;
  height: 12%;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.helpDeskHotSpot {
  opacity: 0;
  position: absolute;
  background-color: red;
  top: 55%;
  left: 35%;
  width: 20%;
  height: 25%;
  cursor: pointer;
}

.lobbyVideoScreen {
  position: absolute;
  top: 36.7%;
  left: 37.8%;
  width: 13.9%;
  z-index: 100;
}
