.goToPresentation {
  position: absolute;
  opacity: 0.5;
  //background-color: red;
  top: 30%;
  left: 90%;
  width: 10%;
  height: 70%;
  z-index: 5000;
}

.leavePresentation {
  position: absolute;
  opacity: 0.5;
  //background-color: green;
  top: 8%;
  left: 0%;
  width: 8%;
  height: 15%;
  z-index: 5000;
  cursor: pointer;
}

.exitHoverHotspot {
  position: absolute;
  opacity: 0;
  //background-color: blue;
  top: 16%;
  left: 16%;
  width: 20%;
  height: 15%;
  z-index: 5000;
}

.iFrameDiv {
  position: absolute;
  top: 17.5%;
  left: 7.8%;
  width: 71.3%;
  height: 71.3%;
}

.iFrameClass {
  height: 100%;
  width: 100%;
  border: 0px;
}

.slidoDiv {
  position: absolute;
  width: 17%;
  height: 61%;
  left: 81.9%;
  top: 27%;
}

.slidoContainerDiv {
  position: absolute;
  width: 18%;
  height: 71%;
  left: 81.4%;
  top: 18.4%;
}

.slidoContainer {
  height: 100%;
  width: 100%;
}

.largeBreakoutiFrameDiv {
  position: absolute;
  top: 8%;
  left: 5.5%;
  width: 90%;
  height: 91%;
}
