.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.zoomHoverHotspot {
  position: absolute;
  top: 30.9%;
  left: 30.6%;
  width: 30.5%;
  height: 30%;
}

.lobbyHoverHotspot {
  position: absolute;
  top: 12%;
  left: 60%;
  width: 20%;
  height: 20%;
}

.galleryHoverHotspot {
  position: absolute;
  top: 28%;
  left: 6%;
  width: 30%;
  height: 18%;
}

.roundtablesHoverHotspot {
  position: absolute;
  top: 36%;
  left: 59%;
  width: 15%;
  height: 10%;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}
