@import "./../shared.scss";

// Layout within modal
// Fill whole of remaining space
.scrollableArea {
  // Scale to fill available space
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.modalClose {
  float: right;
}

.columnContainer {
  display: flex;
}

.leftProfileColumn {
  margin-top: 20px;
  width: 50%;
  padding: 0px;
  border-right: 1px solid grey;
}

.rightProfileColumn {
  margin-top: 20px;
  width: 50%;
  padding: 10px;
  padding: 0px 20px 20px 40px;
}

// Single column < 800px
@media (max-width: 800px) {
  .columnContainer {
    display: block;
  }
  .leftProfileColumn {
    width: 100%;
    margin: 0;
  }
  .rightProfileColumn {
    width: 100%;
    margin: 0;
  }
}

.profilePictureAndTextFieldsDiv {
  display: flex;
  flex-grow: 1;
  padding: 0px;
}


// Profile Picture layout
.profilePictureArea {
  width: 180px;
  height: 180px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
}

.profilePictureBackground {
  position: absolute;
  width: 100%;
}

.profilePicture {
  position: absolute;
  width: 97.4%;
  left: 1.3%;
  top: 1.3%;
  height: 97.4%;
}

.profileIcon {
  width: 40px;
  margin-top: 20px;
  margin-right: 20px;
  float: left;
  display: none;
}


// Text field formatting
.textAnswerFieldsDiv {
  flex: 1;
  padding: 30px;
}

.aboutTitlesText {
  color: $color-gallery-feature-text;
  font-weight: 700;
 font-size: 1.5em;
}

.profileParameterTitles {
  color: $color-gallery-feature-text;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 0em;
  //line-height: 0.9;
  //margin-left: 60px;
}

.profileParameterAnswer {
 color: black;
}

.requestMeetingTitle {
  color: $color-gallery-feature-text;
}

// Meeting slot options
.meetingSlotsList {
  width: 100%;
  display: table;
}

.meetingSlotsButton {
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 85px;
  //height: 65px;
}

.meetingSlotsButton:checked {
  margin: 20px;
}
