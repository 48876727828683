@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.lobbyHoverHotspot {
  position: absolute;
  opacity: 0;
  //background-color: green;
  top: 30%;
  left: 80%;
  width: 20%;
  height: 25%;
  z-index: 5000;
}

.goToPresentation {
  position: absolute;
  opacity: 0;
  //background-color: green;
  top: 30%;
  left: 0%;
  width: 45%;
  height: 50%;
  z-index: 5000;
}

.leavePresentation {
  position: absolute;
  opacity: 0;
  //background-color: red;
  top: 90%;
  left: 20%;
  //width: 60%;
  //height: 10%;
  width: 0;
  height: 0;
  z-index: 5000;
}

.iFrameDiv {
  position: absolute;
  width: 77.9%;
  height: 77.9%;
  left: 3.4%;
  top: 15.4%;
}

.widgetContainerDiv {
  position: absolute;
  width: 17%;
  height: 67%;
  left: 82.4%;
  top: 15.4%;
  //background-color: red;
}
