@import "./../shared.scss";

.background {
  background-color: white;
  width: 100%;
  height: 100%;
}

.remoteControlArea {
  margin-left: 50px;
  margin-top: 120px;
}
