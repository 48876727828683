@import "./SharedColors.module.scss";

// Mobile vs desktop version selection
@media (min-width: 700px) {
  .visibleOnlyOnMobile {
    display: none;
  }
}

@media (max-width: 699px) {
  .visibleOnlyOnDesktop {
    display: none;
  }
}

// Standard mobile layouts
.mobileListView {
  background-color: white;
  // Avoid top nav
  padding-top: 50px;
  color: white;
  // Important to avoid bottom nav - may need to be %
  padding-bottom: 150px;
}

.mobileTitleArea {
  background-color: $color-beehive-blue;
  height: 110px;
  border-radius: 10px;
  padding: 10px;
  padding-top: 20px;
  text-align: center;
}

.mobileTitleText {
  margin-bottom: 0;
}

.mobileListItem {
  background-color: $color-beehive-blue;
  min-height: 100px;
  margin: 2%;
  border-radius: 10px;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-left: $color-beehive-pink 10px solid;
}

.mobileListTitle {
  font-weight: 700;
}