@import "./../shared.scss";

.textEntry {
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  border: 1px lightgrey solid;
  color: $color-login-text;
  margin-bottom: 10px;
}

.nextBackButtonsRow {
  width: 100%;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.introText {
  font-size: 1em;
  color: $color-profile-text;
}

.subheading {
  font-size: 1.8em;
}

// Hide standard file upload button
input[type="file"] {
  display: none;
}

.profileVideoSelection {
  display: none;
}