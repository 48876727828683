.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.lobbyHoverHotspot {
  top: 64.8%;
  left: 78.1%;
  width: 23.1%;
  height: 17%;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.videoDiv {
  position: absolute;
  top: 100px;
  left: 100px;
  width: 800px;
  height: 100px;
  z-index: 1050;
}

.iFramePosition0 {
  position: absolute;
  visibility: hidden;
}

.iFramePosition1 {
  position: absolute;
  z-index: 500;
  top: 22%;
  left: 36%;
  width: 63%;
  height: 58%;
  transition: all 1s;
}

.iFramePosition2 {
  position: absolute;
  z-index: 500;
  top: 70px;
  left: 1%;
  width: 98%;
  //height: 90%;
  bottom: 10px;
  transition: all 1s;
}

.videoPip {
  top: 17%;
  left: 21%;
  width: 57%;
  height: 61%;
  position: absolute;
  z-index: 200;
}

.goToPresentation {
  position: absolute;
  opacity: 0;
  //background-color: green;
  top: 30%;
  left: 0%;
  width: 45%;
  height: 50%;
  z-index: 5000;
}

.leavePresentation {
  position: absolute;
  opacity: 0;
  background-color: red;
  top: 15%;
  left: 0%;
  //width: 60%;
  //height: 10%;
  width: 3.5%;
  height: 9%;
  z-index: 5000;
}

.lobbyHoverHotspot {
  position: absolute;
  opacity: 0;
  //background-color: green;
  top: 25%;
  left: 6%;
  width: 10%;
  height: 10%;
  z-index: 5000;
}

.iFrameClass {
  height: 100%;
  width: 100%;
  border: 0px;
}

.slidoDiv {
  position: absolute;
  width: 16%;
  height: 61%;
  left: 82.3%;
  top: 26.5%;
}

.slidoContainerDiv {
  position: absolute;
  width: 18%;
  height: 71%;
  left: 81.4%;
  top: 18.4%;
}

.slidoContainer {
  height: 100%;
  width: 100%;
}

.iFrameDiv {
  position: absolute;
  width: 68.5%;
  height: 68.5%;
  left: 4%;
  top: 17.8%;
}

.switchToSimTransButton {
  position: absolute;
  width: 90%;
  height: 10%;
  left: 5%;
  top: 0%;
}
