@import "./../shared.scss";

.tabSelectionButtonPoster {
  margin-top: 5px;
  margin-left: 0%;
  margin-right: 2%;
  float: right;
}

.tabSelectionButtonQuestion {
  margin-top: 5px;
  margin-left: 0%;
  margin-right: 2%;
  float: right;
}

.tabSelectionButtonRoundtable {
  margin-top: 5px;
  margin-left: 0%;
  margin-right: 2%;
  float: right;
}

.tabSelectionButtonPlusOne {
  margin-top: 0px;
  width: 50px;
  float: right;
}

.heroImage {
  width: 100%;
  padding: 5%;
}

.posterVideoDiv {
  width: 100%;
  padding: 5%;
}

.aboutTextDiv {
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  background-color: $color-light-grey-background;
  overflow-y: scroll;
  border-top: 2px $color-dark-grey solid;
}

.aboutText {
  font-size: 1em;
}

.posterPreview {
  width: 90%;
  margin-left: 5%;
}

.enlargeHint {
  margin-top: 30px;
  text-align: center;
  color: $color-gallery-feature-text;
  //background-color: white;
}

.votingInfo {
  margin-top: 0px;
  font-size: 1.2em;
  text-align: center;
}

.posterNameText {
  font-size: 1.5em;
}

.posterPresenterNameText {
  font-size: 1.5em;
}
