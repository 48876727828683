@import "./../shared.scss";

.circlesParticipantsOverlayArea {
  position: absolute;
  background-color: white;
  right: 0%;
  top: 80%;
  min-width: 15%;
  height: 13%;
  z-index: 10000;
  border-radius: 12px 0% 0% 12px;
  box-shadow: 5px 5px 15px #333333;
}

.circlesOpenCloseButtonArea {
  position: absolute;
  background-color: $color-beehive-blue;
  right: 0;
  top: 80%;
  width: 3%;
  height: 13%;
  border-radius: 12px 0% 0% 12px;
  z-index: 10001;
}

.circlesBarLogo {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.circlesOpenCloseIcons {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 70%;
  top: 15%;
}

.notificationOuterCircle {
  position: absolute;
  background-color: white;
  height: 2vw;
  top: -12%;
  left: -1vw;
  border-radius: 50%;
}

.notificationInnerCircle {
  position: absolute;
  background-color: #e6e6e6;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;

  height: 75%;
  width: 75%;
  border-radius: 50%;
}

.circlesBarParticipantsArea {
  height: 100%;
  margin-left: 11vw;
  margin-right: 3.5vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

.profileIconSlot {
  position: relative;
  height: 100%;
  display: inline-block;
}

.profilePictureSurround {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 50%;
}

.profileIcon {
  position: relative;
  height: 90%;
  top: 5%;
  left: 5%;
}
