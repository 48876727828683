@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
}

// New elements for Dec 2020 Exhibitions - review above
.screenPanel {
  position: absolute;
  top: 25.8%;
  left: 16.27%;
  width: 16.52%;
  height: 33.5%;
  z-index: 11;
}

.screenPanelShadow {
  position: absolute;
  top: 22.6%;
  left: 14.4%;
  width: 20.3%;
  height: 40%;
  z-index: 10;
}

.deskPanel {
  position: absolute;
  top: 62.7%;
  left: 35.9%;
  width: 28.2%;
  height: 17.6%;
  border-radius: 4% / 12%;
  padding: 20px;
}

.deskPanelGraphic {
  position: absolute;
  top: 62.7%;
  left: 35.9%;
  width: 28.2%;
  height: 17.6%;
  border-radius: 4% / 12%;
}

.resourcePanelTop {
  position: absolute;
  top: 25.8%;
  left: 67.4%;
  width: 16.7%;
  height: 9%;
  z-index: 11;
}

.resourcePanelTopShadow {
  position: absolute;
  top: 22.7%;
  left: 65.5%;
  width: 20.4%;
  height: 15.3%;
  z-index: 10;
}

.resourcePanelBottom {
  position: absolute;
  top: 36.2%;
  left: 67.4%;
  width: 16.7%;
  height: 28.5%;
  z-index: 11;
}

.resourcePanelBottomShadow {
  position: absolute;
  top: 32.8%;
  left: 65.5%;
  width: 20.4%;
  height: 35.2%;
  z-index: 10;
}

.backPanel {
  position: absolute;
  top: 25.8%;
  left: 34.2%;
  width: 31.8%;
  height: 31.9%;
  z-index: 10;
}

.videoScreen1 {
  width: 100%;
  height: 100%;
}

.videoScreen1Frame {
  position: absolute;
  top: 34.2%;
  left: 17%;
  width: 15%;
  height: 15.5%;
  z-index: 20;
  background-color: black;
  border: 5px solid black;
}

.resourceArea {
  position: absolute;
  top: 25.8%;
  left: 67.4%;
  width: 16.7%;
  height: 9%;
  z-index: 30;
  cursor: pointer;
}

.resourceAreaIcon {
  position: absolute;
  width: 20%;
  top: 15%;
  left: 5%;
}

.resourceAreaTitleText {
  position: absolute;
  width: 70%;
  top: 25%;
  left: 30%;
  color: white;
  font-size: 1vw;
  text-shadow: 1px 1px #343434;
}

.resourceAreaSubtitleText {
  position: absolute;
  width: 70%;
  top: 55%;
  left: 30%;
  color: white;
  font-size: 0.75vw;
  text-shadow: 1px 1px #343434;
}

.contactArea {
  position: absolute;
  top: 36%;
  left: 67.4%;
  width: 16.7%;
  height: 28%;
  z-index: 30;
}

.contactAreaTitleText {
  position: absolute;
  width: 70%;
  top: 6%;
  left: 6%;
  color: white;
  font-size: 1.5vw;
  text-shadow: 1px 1px #343434;
}

.contactOptionsDiv {
  position: absolute;
  top: 20%;
  left: 0%;
  width: 100%;
  height: 80%;
}

.contactOptionDiv {
  position: relative;
  width: 100%;
  height: 25%;
  cursor: pointer;
}

.contactOptionIcon {
  position: absolute;
  width: 20%;
  top: 0%;
  left: 5%;
}

.contactOptionText {
  position: absolute;
  width: 70%;
  top: 40%;
  left: 30%;
  color: white;
  font-size: 1vw;
  text-shadow: 1px 1px #343434;
}

.backToHall {
  position: absolute;
  top: 90%;
  left: 35%;
  width: 30%;
  height: 10%;
}

.nextStand {
  position: absolute;
  top: 54%;
  left: 88%;
  width: 12%;
  height: 30%;
}

.previousStand {
  position: absolute;
  top: 54%;
  left: 0%;
  width: 12%;
  height: 30%;
}

// Q&A Pop Up Styling
.exhibitionTitleBar {
  background-color: $color-modal-title-bar-background;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 30px;
  color: $color-modal-title-bar-text;
  background-size: 100% 100%;
  background-position: top right;
  background-repeat: no-repeat;
}

// Q&A Pop Up Styling
.resourceAreaTitleBar {
  background-color: white;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 30px;
  color: $color-modal-title-bar-text;
  height: 70px;
}

.exhibitionPageTitle {
  display: inline;
}

.modalClose {
  float: right;
  margin-right: 20px;
  margin-top: 12px;
  background-color: Transparent;
  border: 0px;
  color: white;
}

.modalCloseDark {
  float: right;
  margin-right: 20px;
  margin-top: 12px;
  background-color: Transparent;
  border: 0px;
  color: black;
}

.closeIcon {
  margin-left: 20px;
  width: 20px;
  margin-top: -3px;
}

.chatFunctionContainer {
  position: relative;
  width: 90%;
  // Chat area is natively 400px tall
  height: 400px;
  margin-left: 3%;
  margin-top: 5%;
  margin-bottom: 20px;
}

.resourceAreaCompanyLogo {
  width: 250px;
  height: 50px;
  //margin-top: -10px;
  margin-left: -10px;
}

@media (max-width: 800px) {
  .resourceAreaCompanyLogo {
    width: 150px;
    height: 30px;
    margin-top: 10px;
  }
}

.deskFrontLogo {
  width: 100%;
  height: 100%;
}

// Scroll element
// Used by Q&A tab
// Fill whole of remaining space
.scrollableArea {
  // Scale to fill available space
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}
