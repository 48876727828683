@import "./../shared.scss";

// Column Structure
// 2 Column > 800px;
.columnContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.formColumn {
  padding-top: 40px;
  padding-bottom: 0px;
  width: 50%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  // Removed height which is usually required for such pages
  //height: 100%;
}

// Single column < 800px
@media (max-width: 800px) {
  .columnContainer {
    display: block;
  }
  .formColumn {
    width: 100%;
    margin: 0;
  }
}

.boothVideo1 {
  width: 100%;
}

.boothVideo2 {
  margin-top: 10%;
  width: 100%;
}

.learnMoreDiv {
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 4%;
}

.learnMoreTitle {
  color: black;
}

.learnMoreTable {
  width: 100%;
  height: 85%;
}

.column1 {
  width: 45%;
}

.column2 {
  width: 45%;
}

.learnMoreTableRow {
  height: 20%;
}

.learnMoreButton {
  width: 95%;
  height: 80%;
  font-size: 1vw;
  color: white;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
}

.learnMoreButtonText {
  position: relative;
  text-align: center;
  width: 100%;
  font-size: 18px;
  top: 5%;
}
