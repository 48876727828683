@import "./../shared.scss";

.chatWindow {
  border: 0px solid #ccc;
  border-radius: 3px;
  height: 90%;
  width: 95%;
  margin: 0.5em;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  top: 3%;
  background-color: rgba(0, 0, 0, 0);
}

.circleTitle {
  flex: 0 0 20px;
  color: $color-beehive-blue;
  padding: 0px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: 700;
}

.chatWindow .MessageList {
  box-sizing: border-box;
  flex: 1;
  margin-bottom: 20px;
  border-radius: 10px;
}

.spacer {
  flex: 0 0 10px;
  opacity: 0;
}

.chatWindow .MessageForm {
  background: #eee;
  border-top: 1px solid #ccc;
  border-radius: 0 0 3px 3px;
  box-sizing: border-box;
  flex: 0 0 30px;
  border-radius: 5px;
}

.circlesChatArea {
  position: absolute;
  height: 25%;
  top: 52%;
  right: 5%;
  width: 20%;
  z-index: 1000000;
}

.semiOpaqueChatAreaBackground {
  background-color: #bbbbbb;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0.7;
  border-radius: 10px;
}
