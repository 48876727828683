// Colours
$color-light-grey: #e5eceb;
$color-dark-grey: #7d868c;
$color-black: #333e48;
$color-light-black: #333e48;
$color-very-light-grey-background: #f1f1f1;
$color-light-blue: #00c4e2;
$color-light-grey-background: #e5e5e5;
$color-default-agenda-item-background-grey: #f2f2f2;
$color-default-agenda-item-spacer-grey: #b9b9b9;

$color-beehive-blue: #1b1464;
$color-beehive-pink: #ed1e79;
$color-beehive-light-grey: #f2f2f2;
$color-beehive-purple: #473087;
$color-panel-off-white: #f9f8f9;


// Allocations of colour
$color-master-background: $color-beehive-purple;
$color-background: $color-very-light-grey-background;
$color-header-bar: $color-light-grey-background;
$color-title-text: $color-light-black;

$color-login-text: black;
$color-login-feature-text: $color-beehive-purple;
$color-login-background: $color-panel-off-white;

$color-button-standard: grey;
$color-button-highlighted: $color-beehive-pink;

$color-button-text: white;
$color-button-primary: grey;
$color-button-secondary: $color-beehive-blue;
$color-button-success: $color-beehive-pink;

$modal-border: 2px black solid;
$color-modal-background: white;
$color-modal-title-bar-background: $color-beehive-blue;
$color-modal-title-bar-text: white;

$color-agenda-text: $color-beehive-purple;
$color-agenda-titles: black;
$color-agenda-item-background: $color-default-agenda-item-background-grey;
$color-agenda-item-spacer: $color-default-agenda-item-spacer-grey;

$color-modal-text: $color-black;

$color-gallery-feature-text: $color-beehive-purple;

$color-gallery-item-border: black;
$color-gallery-item-background: $color-beehive-light-grey;
$color-gallery-user-modal-background: white;

$color-meeting-text: $color-black;

$color-menu-bar-background: $color-beehive-purple;
$color-menu-bar-text: white;
$color-menu-bar-feature: $color-beehive-pink;
$color-menu-bar-feature-highlight: #d80868;

$color-profile-stages-column-background: $color-light-grey;
$color-profile-background: white;
$color-profile-text: $color-dark-grey;
