@import "./../../shared.scss";


// Desktop agenda item layout
.desktopAgendaItem {
  background-color: $color-agenda-item-background;
  display: flex;
  border-radius: 0 10px 10px 0;
  margin-bottom: 6px;
}

.sideBorderArea {
  border-radius: 10px 0 0 10px;
  background-color: $color-beehive-purple;
  flex:1;
}

.timeAndTitleArea {
  flex: 50;
  padding: 10px;
}

.spacerArea {
  width: 1px;
  background-color: $color-agenda-item-spacer;
  padding: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.presenterArea {
  flex: 20;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.linkArea {
  flex: 5;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

// Text styling

.sessionTimeText {
  font-size: 20px;
  font-weight: 700;
  color: $color-agenda-text;
}

.sessionLocationText {
  font-size: 20px;
  color: $color-agenda-text;
  font-style: italic;
}

.uncolouredLink {
  color: $color-agenda-text;
}

.sessionTitleText {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: $color-agenda-titles;
}

.sessionDetailText {
  font-size: 20px;
  font-weight: 700;
  color: $color-agenda-titles;
}

// Huddle up the table rows between what are technically paragraphs
p {
  margin-bottom: 6px;
}

// Mobile additions
.mobileAgendaItem {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.agendaRowBlock {
  flex: 30;
  background-color: $color-agenda-item-background;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 15px;
  min-height: 100px;
}
