@import "./../../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.widgetContainerBackground {
  height: 100%;
  width: 100%;
}

.widgetDiv {
  position: absolute;
  top: 9%;
  left: 1%;
  width: 98%;
  height: 91%;
}

.switchToSimTransButton {
  position: absolute;
  width: 50%;
  height: 12%;
  top: 0%;
  left: 50%;
  cursor: pointer;
}

.switchToSlidoButton {
  position: absolute;
  width: 50%;
  height: 12%;
  top: 0%;
  left: 0%;
  cursor: pointer;
}

.fullWidgetArea {
  height: 100%;
  width: 100%;
}
