@import "./../shared.scss";

.formContainer {
  margin-left: 5%;
  width: 90%;
  margin-top: 5%;
}

.textEntry {
  width: 100%;
}
