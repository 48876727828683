@import "./../shared.scss";
@import "./../sharedStyles/SharedGallery.module.scss";

.outerBackgroundDiv {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
}

.jitsiDiv {
  position: absolute;
  width: 80%;
  height: 45vw;
  top: 250px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 30000;
}

@media (max-width: 800px) {
  .jitsiDiv {
    position: absolute;
    width: 100%;
    height: 56.25vw;
    top: 250px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 30000;
  }
}

.iFrameDiv {
  top: 250px;
  width: 60%;
  bottom: 10%;
  //background-color: red;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.newiFrameDiv {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  aspect-ratio: 16/9;
  top: 250px;
}

.iFrame {
  width: 100%;
  height: 100%;
}

.chatDiv {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 920px;
  height: 400px;
  top: 300px;
}
