@import "./../shared.scss";
@import "./../sharedStyles/SharedGallery.module.scss";

.profileSegmentsArea {
  padding: 5%;
  padding-top: calc(80px + 2%);
  width: 100%;
  position: absolute;
  top: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  z-index: 3000;
  justify-items: center;
}

.exhibitionStandLink {
  text-decoration: none !important;
}

.exhibitionStandContainer {
  background-color: $color-gallery-item-background;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 350px;
  height: 450px;
  border-width: 2px;
  border-color: $color-gallery-item-border;
  border-style: solid;
  z-index: 3220;
  box-shadow: 10px 10px 25px 8px grey;
  padding: 20px;
  position: relative;
}

.companyLogo {
  margin-left: 2px;
  width: 300px;
  height: 100px;
  left: 0;
  right: 0;
}

.exhibitionStandNameContainer {
  width: 100%;
  height: 120px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 20px;
  z-index: 3221;
  color: black;
}

.standNameText,
.standNameText:hover {
  color: black;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 20px;
}

.exhibitionStandSummaryText,
.exhibitionStandSummaryText:hover {
  color: black;
  font-size: 1em;
}

.visitStandButton {
  width: 150px;
  height: 45px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  z-index: 3201;
  font-size: 1.2em;
  padding-top: 7px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  bottom: 25px;
}
