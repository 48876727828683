@import "./../shared.scss";

.viewerArea {
  position: absolute;
  top: 0%;
  left: 00%;
  width: 100%;
  height: 100%;
  background-color: yellow;
}
