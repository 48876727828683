@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.talksHoverHotspot {
  top: 55.9%;
  left: 10.6%;
  width: 23.5%;
  height: 12%;
}

.lobbyHoverHotspot {
  top: 55.2%;
  left: 2.6%;
  width: 17.4%;
  height: 10%;
}

.breakoutsHoverHotspot {
  top: 46%;
  left: 21%;
  width: 5%;
  height: 4%;
}

.networkingHoverHotspot {
  top: 23%;
  left: 9%;
  width: 10%;
  height: 4%;
}

.helpDeskHotSpot {
  top: 53%;
  left: 23%;
  width: 7%;
  height: 7%;
  cursor: pointer;
}

.galleryHoverHotspot {
  top: 60.6%;
  left: 18.4%;
  width: 45.3%;
  height: 30.8%;
}

.meetupsHoverHotspot {
  top: 71.3%;
  left: 24.1%;
  width: 23.2%;
  height: 8%;
}

.playHoverHotspot {
  top: 46.9%;
  left: 49.5%;
  width: 7.2%;
  height: 6%;
}

.plenaryHoverHotspot {
  top: 4.5%;
  left: 65.4%;
  width: 20%;
  height: 12%;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.posterHoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.posterNamePopUp {
  position: absolute;
  width: 200px;
  height: 180px;
  background-color: $color-dark-grey;
  color: white;
  text-align: center;
  padding: 5px;
  padding-top: 20px;
  border-radius: 10px;
  bottom: 0px;
  //overflow-y: scroll;
  z-index: 30000;
}

.posterNamePopUpContainer {
  position: absolute;
  width: 200px;
  height: 45%;
  //background-color: yellow;
  //opacity: 0.3;
}

.joinButton {
  position: absolute;
  bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  width: 100px;
}
