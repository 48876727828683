.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.lobbyHoverHotspot {
  top: 40.3%;
  left: 82.6%;
  width: 10.9%;
  height: 15%;
}

.smallBreakout1HoverHotspot {
  top: 10%;
  left: 10%;
  width: 70%;
  height: 80%;
}

.smallBreakout2HoverHotspot {
  top: 7%;
  left: 70%;
  width: 0%;
  height: 0%;
}

.smallBreakout3HoverHotspot {
  top: 48%;
  left: 30%;
  width: 0%;
  height: 0%;
}

.smallBreakout4HoverHotspot {
  top: 65%;
  left: 65%;
  width: 0%;
  height: 0%;
}

.largeBreakoutHoverHotspot {
  top: 68%;
  left: 2%;
  width: 0%;
  height: 0%;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}
