.notificationPosition {
  position: absolute;
  left: 35%;
  top: 25%;
  width: 30%;
  min-height: 200px;
  z-index: 1000000;
  overflow-y: scroll;
  background-color: white;
  text-align: center;
  box-shadow: 10px 10px 25px 8px grey;
  border-radius: 30px;
}

.notificationNotVisible {
  @extend .notificationPosition;
  opacity: 0;
  transition: opacity 1s;
}

.notificationVisible {
  @extend .notificationPosition;
  opacity: 1;
  transition: opacity 1s;
}

.notificationClose {
  position: absolute;
  top: 3%;
  right: 2%;
  width: 8%;
  z-index: 200;
  cursor: pointer;
}

.notificationBellIcon {
  position: absolute;
  top: 4%;
  left: 2%;
  width: 10%;
  z-index: 200;
}

.notificationText {
  color: black;
  font-size: 1vw;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 3%;
}