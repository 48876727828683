@import "./../shared.scss";

@media (max-width: 699px) {
  .navBlockContainer {
    display: none;
  }
}

.navBlockContainer {
  position: fixed;
  top: 65px;
  right: 0px;
  width: 16%;
  height: 0px;
  padding-top: 16%;
  z-index: 6000;
}

.navBlock {
  font-size: 0.7em;
  position: absolute;
  width: 50%;
  height: 50%;
  z-index: 2000;
  padding-top: 12%;
  color: white;
  opacity: 0.9;
  transition: opacity 250ms;
}

.navBlock:hover {
  opacity: 1;
  //background-color: #d0d0d0;
  transition: opacity 250ms;
}

.navBlockInactive {
  position: absolute;
  width: 33%;
  height: 33%;
  z-index: 2000;
}

.agendaBlock {
  top: 0px;
  right: 50%;
  background-size: 100%;
  //border: 1px #ececec solid;
  //background-color: $color-beehive-pink;
  //border: 1px #ececec solid;
  cursor: pointer;
}

.leaderboardBlock {
  top: 0%;
  right: 0%;
  background-size: 100%;
  //border: 1px #ececec solid;
  //background-color: $color-beehive-pink;
  //border: 1px #ececec solid;
  cursor: pointer;
}

.helpBlock {
  top: 50%;
  right: 0%;
  background-size: 100%;
  //border: 1px #ececec solid;
  //background-color: $color-beehive-pink;
  //border: 1px #ececec solid;
  cursor: pointer;
}

.stackedNavBlockContainer {
  position: fixed;
  top: 65px;
  right: 0px;
  width: 8%;
  height: 0px;
  padding-top: 24%;
  z-index: 6000;
}

.stackedNavBlock {
  font-size: 0.7em;
  position: absolute;
  width: 100%;
  height: 33%;
  z-index: 2000;
  padding-top: 12%;
  color: white;
  opacity: 0.7;
  transition: opacity 250ms;
}

.stackedNavBlock:hover {
  opacity: 1;
  transition: opacity 250ms;
}

.stackedAgendaBlock {
  background-color: #95d600;
  top: 33%;
  right: 0%;
  background-size: 100%;
}

.stackedLeaderboardBlock {
  background-color: #ff6b00;
  top: 66%;
  right: 0%;
  background-size: 100%;
}

.stackedHelpBlock {
  background-color: #00c1de;
  top: 0%;
  right: 0%;
  background-size: 100%;
}

// Bottom bar for mobile;
.bottomBar {
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  padding: 0% 5% 2% 5%;
  z-index: 10000;
}

.bottomBarFlexContainer {
  display: flex;
}

.bottomBarDecorativeLine {
  width: 100%;
  height: 2px;
  background-color: #bbbbbb;
  display: block;
  margin-bottom: 8px;
}

.bottomBarElement {
  flex-grow: 1;
  cursor: pointer;
}

.bottomBarIconImage {
  text-align: center;
  width: 30px;
  margin-left: 25%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2px;
}

.bottomBarIconText {
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: black;
}

.bottomBarIconText:hover {
  // No change to styling
}

@media (max-width: 360px) {
  .hideOnNarrowScreen {
    display: none;
  }
}

a {
  text-decoration: none !important;
}
