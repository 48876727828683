@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

$NineStandsWidth: 20%;
$NineStandsHeight: 16%;
$NineHorizOffset: 17%;
$NineHorizSpace: 23%;
$NineVertOffset: 24%;
$NineVertSpace: 19%;

.lobbyHoverHotspot {
  //background-color: red;
  top: 84%;
  left: 46%;
  width: 8%;
  height: 13%;
}

.searchHoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
}

.searchHoverHotspot:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

.stand1Of9Hotspot {
  top: $NineVertOffset;
  left: $NineHorizOffset;
  width: $NineStandsWidth;
  height: $NineStandsHeight;
}

.stand2Of9Hotspot {
  top: $NineVertOffset;
  left: $NineHorizOffset + $NineHorizSpace;
  width: $NineStandsWidth;
  height: $NineStandsHeight;
}

.stand3Of9Hotspot {
  top: $NineVertOffset;
  left: $NineHorizOffset + 2 * $NineHorizSpace;
  width: $NineStandsWidth;
  height: $NineStandsHeight;
}

.stand4Of9Hotspot {
  top: $NineVertOffset + $NineVertSpace;
  left: $NineHorizOffset;
  width: $NineStandsWidth;
  height: $NineStandsHeight;
}

.stand5Of9Hotspot {
  top: $NineVertOffset + $NineVertSpace;
  left: $NineHorizOffset + $NineHorizSpace;
  width: $NineStandsWidth;
  height: $NineStandsHeight;
}

.stand6Of9Hotspot {
  top: $NineVertOffset + $NineVertSpace;
  left: $NineHorizOffset + 2 * $NineHorizSpace;
  width: $NineStandsWidth;
  height: $NineStandsHeight;
}

.stand7Of9Hotspot {
  top: $NineVertOffset + 2 * $NineVertSpace;
  left: $NineHorizOffset;
  width: $NineStandsWidth;
  height: $NineStandsHeight;
}

.stand8Of9Hotspot {
  top: $NineVertOffset + 2 * $NineVertSpace;
  left: $NineHorizOffset + $NineHorizSpace;
  width: $NineStandsWidth;
  height: $NineStandsHeight;
}

.searchStandOf9Hotspot {
  top: 64.45%;
  left: 63.6%;
  width: 18.9%;
  background-color: red;
}

.previousHallHotspot {
  top: 46%;
  left: 5%;
  width: 8%;
  height: 11%;
}

.nextHallHotspot {
  top: 46%;
  left: 87%;
  width: 8%;
  height: 11%;
}
