@import "./../shared.scss";
@import "./../sharedStyles/SharedGallery.module.scss";

.profileSegmentsArea {
  padding: 150px 50px 150px 50px;
  width: 100%;
  position: absolute;
  top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  z-index: 3000;
  justify-items: center;
}

.roundtableItemOld {
  position: relative;
  border-radius: 100%;
  width: 300px;
  height: 300px;
  background-color: $color-beehive-blue;
  margin: 0px 0;
  padding-top: 20px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.6);
}

.roundtableItem {
  position: relative;
  border-radius: 10px;
  width: 300px;
  height: 300px;
  background-color: $color-gallery-item-background;
  margin: 0px 0;
  padding-top: 20px;
}

.roundtableItemBackground {
  position: absolute;
  width: 220px;
  left: 40px;
  top: 40px;
}

.roundtableGridItem {
  margin-top: 50px;
  margin-bottom: 50px;
}

.tableNumber {
  font-size: 1em;
  text-align: center;
}

.tableName {
  color: $color-gallery-feature-text;
  //text-align: center;
  text-align: left;
  font-size: 1.6em;
  vertical-align: middle;
}

.tableNameVerticalCentre {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  width: 70%;
  margin: auto;
}

.innerContainer {
  width: 100%;
  height: 170px;
  position: relative;
  line-height: 30px;
}

.lowerRoundtableArea {
  position: absolute;
  font-size: 1.3em;
  //top: 0px;
  //width: 100%;
  text-align: left;
  height: 40px;
  width: 100%;
  bottom: 0px;
  margin-left: 0px;
  //background-color: red;
}

.profilePicture {
  display: inline;
}

.exampleRoundtable {
  width: 420px;
}

.joinButton {
  position: absolute;
  width: 100px;
  //float: bottom-right;
  bottom: -80px;
  left: 0px;
  right: 0px;
  margin: auto;

  padding: px;
  z-index: 3201;
}

.detailButton {
  position: absolute;
  width: 40px;
  //float: bottom-right;
  bottom: 0px;
  right: 0px;
  padding: px;
  z-index: 3201;
}

@media (max-width: 1600px) {
  .roundtablesInfo {
    font-size: 1.6em;
  }
}

@media (max-width: 1400px) {
  .roundtablesInfo {
    font-size: 1.5em;
  }
}

@media (max-width: 1200px) {
  .roundtablesInfo {
    font-size: 1.4em;
  }
}
