@import "./../shared.scss";
@import "./../sharedStyles/SharedGallery.module.scss";


.searchTitleAreaVisual {
  position: absolute;
  top: 18%;
  width: 800px;
  left: 0px;
  right: 16.5%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 3001;
}

.profileSegmentsArea {
  padding: 5%;
  padding-top: 2%;
  width: 100%;
  position: absolute;
  top: 185px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  z-index: 3000;
  padding-bottom: 20%;
  justify-items: center;
}

.profileSegmentsAreaVisual {
  position: absolute;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 2%;
  left: 4%;
  width: 76%;
  top: calc(17% + 100px);
  height: 60%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  justify-items: center;
  z-index: 3000;
  overflow-y: auto;
  justify-items: center;
}

.userContainer {
  background-color: $color-gallery-item-background;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 50px;
  width: 220px;
  box-sizing: border-box;
  z-index: 3220;
  padding: 0px;
  max-height: 340px;
}

.profilePictureArea {
  width: 180px;
  height: 180px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
}

.profilePictureBackground {
  position: absolute;
  width: 100%;
}

.profilePicture {
  position: absolute;
  width: 97.4%;
  left: 1.3%;
  top: 1.3%;
  height: 97.4%;
}

.userNameContainer {
  width: 100%;
  height: 80px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  z-index: 3221;
}

.userNameText {
  font-weight: 700;
  font-size: 20px;
  color: $color-gallery-feature-text;
}

.detailButton {
  width: 50px;
  float: right;
  padding: 10px;
  z-index: 3201;
}

