@import "./SharedColors.module.scss";

// Modal styling
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0;
  z-index: 30000;
}

.modalContent {
  position: absolute;
  top: 70px;
  left: 0px;
  right: 0px;
  width: 1040px;
  margin-left: auto;
  margin-right: auto;
  bottom: 50px;
  border: 4px solid black;
  background: $color-modal-background;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  border-radius: 18px 18px 0 0;
  outline: 0;
  padding: 0px;
  background-position: right bottom;
  background-repeat: no-Repeat;
  box-shadow: 10px 10px 25px 8px grey;
}

@media (max-width: 1040px) {
  .modalContent {
    width: 95%;
  }
}

// Used to fill all available space (effectively get 100% height)
.scrollableArea {
  // Scale to fill available space
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
  margin: 0% 6% 2% 5%;
  padding-top: 2%;
}

// Allows us to create 'whatever vertical space is left' for the scroll to go within
.modalFlexboxColumn {
  display: flex;
  flex-direction: column;
  flex: wrap;
  height: 100%;
}

.modalTitleBar {
  background-color: $color-modal-title-bar-background;
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 30px;
  color: $color-modal-title-bar-text;
  background-size: 100% 100%;
  background-repeat: no-Repeat;
}

// Stops carriage return after title padding the title bar
.modalPageTitle {
  display: inline;
  font-size: 2rem;
}

.modalClose {
  float: right;
  margin-right: 2%;
  margin-top: 0.5%;
  background-color: $color-modal-title-bar-background;
  background-color: Transparent;
  border: 0px;
  color: $color-modal-title-bar-text;
}

.modalCloseIcon {
  margin-left: 20px;
  width: 20px;
  margin-top: -3px;
}

// Arrangement of columns within modal
// Column Structure
// 2 Column > 800px;
.columnContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.formColumn {
  padding-top: 40px;
  padding-bottom: 0px;
  width: 50%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  // Removed height which is usually required for such pages
  //height: 100%;
}

// Single column < 800px
@media (max-width: 800px) {
  .columnContainer {
    display: block;
  }
  .formColumn {
    width: 100%;
    margin: 0;
  }
}

// Modal background overlay styles
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}