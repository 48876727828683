@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.lobbyHoverHotspot {
  //background-color: red;
  top: 84%;
  left: 46%;
  width: 8%;
  height: 13%;
}

.hallButtonOff {
  position: absolute;
  opacity: 1;
  transition: opacity 0.5s;
}

.hallButtonOn {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.hallButtonOff:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.hallButtonOn:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

.hall1Hotspot {
  top: 26%;
  left: 12%;
  height: 31.5%;
}

.hall1HotspotOn {
  top: 26%;
  left: 12%;
  height: 30.5%;
}

.hall2Hotspot {
  top: 26%;
  left: 37%;
  height: 31.5%;
  z-index: 10;
}

.hall2HotspotOn {
  top: 26%;
  left: 37%;
  height: 30.5%;
  z-index: 10;
}

.hall3Hotspot {
  top: 26%;
  left: 61.8%;
  height: 31.5%;
  z-index: 10;
}

.hall3HotspotOn {
  top: 26%;
  left: 61.8%;
  height: 30.5%;
  z-index: 10;
}

.hall4Hotspot {
  top: 53%;
  left: 12%;
  height: 30.5%;
  z-index: 11;
}

.hall5Hotspot {
  top: 53%;
  left: 37%;
  height: 30.5%;
  z-index: 11;
}

.hall6Hotspot {
  top: 53%;
  left: 61.8%;
  height: 30.5%;
  z-index: 11;
}
