@import "./../shared.scss";

.profileSegmentsArea {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  top: 26%;
  width: 70%;
  height: 58%;
  overflow-y: auto;
  z-index: 3000;
}

.profileSegmentsGroup {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
}

.roundtableItem {
  position: relative;
  width: 300px;
  height: 300px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.roundtablesTableBackground {
  position: relative;
  width: 100%;
}

.tableText {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  width: 60%;
  margin: auto;
}

.tableNumber {
  font-size: 1.5em;
  text-align: center;
  color: $color-beehive-pink;
}

.tableName {
  color: $color-beehive-blue;
  text-align: center;
  font-size: 1.5em;
  vertical-align: middle;
}

.roundtablesTitleArea {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 13%;
  width: 22%;
  height: 10%;
}

.roundtablesTitleText {
  position: absolute;
  width: 80%;
  left: 12%;
}

.roundtablesBack {
  position: absolute;
  width: 12%;
}

.roundtablesInfo {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  top: 22%;
  width: 60%;
  font-size: 1.4vw;
  text-align: center;
  color: white;
}

.roundtablesSectionTitle {
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  font-size: 1.8vw;
  text-align: center;
  color: white;
}
