@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.exhibitionHoverHotspot {
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
}
