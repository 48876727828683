@import "./../shared.scss";

.plenaryHoverHotspot {
  position: absolute;
  top: 33.9%;
  left: 20.6%;
  width: 23.5%;
  height: 12%;
}

.postersHoverHotspot {
  top: 85%;
  left: 75%;
  width: 22.2%;
  height: 15%;
}

.exhibitionHoverHotspot {
  top: 83.7%;
  left: 10.3%;
  width: 25%;
  height: 12.5%;
}

.breakoutsHoverHotspot {
  top: 65.7%;
  left: 35.3%;
  width: 25%;
  height: 12.5%;
}

.networkingHoverHotspot {
  top: 12.7%;
  left: 10.3%;
  width: 28%;
  height: 12.5%;
}

.exhibitionHoverHotspot {
  top: 83%;
  left: 10%;
  width: 28%;
  height: 12%;
}

.helpDeskHotSpot {
  opacity: 0;
  position: absolute;
  background-color: red;
  top: 72%;
  left: 58%;
  width: 15%;
  height: 20%;
  cursor: pointer;
}

.lobbyVideoScreenBack {
  position: absolute;
  top: 40.4%;
  left: 71.9%;
  width: 14.6%;
  z-index: 100;
}

.lobbyVideoScreenFront {
  position: absolute;
  top: 61.9%;
  left: 13.9%;
  width: 20.9%;
  z-index: 100;
}
