@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.zoomHoverHotspot {
  position: absolute;
  top: 30.9%;
  left: 30.6%;
  width: 30.5%;
  height: 30%;
}

.unzoomHoverHotspot {
  position: absolute;
  background-color: green;
  top: 0%;
  left: 0%;
  width: 8%;
  height: 25%;
}

.galleryHoverHotspot {
  position: absolute;
  top: 0.9%;
  left: 20%;
  width: 30%;
  height: 95%;
}

.roundtablesHoverHotspot {
  position: absolute;
  top: 0.9%;
  left: 52%;
  width: 30%;
  height: 95%;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.videoDiv {
  position: absolute;
  top: 10%;
  left: 8%;
  width: 87%;
  height: 87%;
  opacity: 1;
}

.iFrameClass {
  height: 100%;
  width: 100%;
  border: 0px;
}
