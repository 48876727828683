@import "./../shared.scss";

.deskPanelGraphicMobile {
  margin: 5%;
  width: 90%;
}

.deskFrontLogoMobile {
  margin: 5%;
  width: 90%;
}

.backPanelMobile {
  margin: 5%;
  width: 90%;
}

.videoScreen1AspectDiv {
  width: 90%;
  margin: 5%;
  padding-bottom: 56.25%;
  position: relative;
}

.videoScreen1FrameMobile {
  position: absolute;
  margin: 100%;
  width: 100%;
}

.videoScreen1Mobile {
  width: 100%;
  height: 100%;
}

.contactOptionIconMobile {
  width: 20%;
  display: inline-block;
}
